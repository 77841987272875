// used in merchant settings - new view
import { MerchantHandler } from "actions/MerchantHandler";
import Observer, { EVENTS } from "classes/Observer";
import Landing from "common/Landing";
import useMerchant from "hooks/useMerchant";
import React, { useEffect } from "react";

const LandingPageContent: React.FC = () => {
  const merchant = useMerchant();
  const observer = Observer.useObserver(EVENTS.MERCHANT_UPDATED);

  useEffect(() => {
    MerchantHandler().get(merchant?.slug);
  }, [observer]);

  return <>
    <div className="logoPreview" style={{ border: "1px solid var(--primaryColor20Solid)" }}>
      <Landing
        id="landingPreview"
        type="MERCHANT"
        preview={true}
        merchantLogoPreview={null}
        merchantInitials={merchant?.name}
        hero={merchant?.industry_template?.hero_image || "/assets/images/merchantLandingBanner.png"}
        subtitle={merchant?.industry_template?.body || null}
        title={merchant?.industry_template?.title || null}
        {...merchant?.color_theme && { colors: [merchant?.color_theme?.primary_light, merchant?.color_theme?.primary_dark, merchant?.color_theme?.secondary] }}
        {...merchant?.primary_logo && { preview_logo: merchant?.primary_logo }}
      />
    </div>
  </>;
}

export default LandingPageContent;