import { IStepper } from "common/interfaces";
import { useAppSelector } from "reducers/Hooks";
import {
  displayApplicationLockedFeedbackPopup,
  prepareNewApplication, scrollTop
} from "utils/helpers";
import BorrowerHandler from "actions/BorrowerHandler";
import { useEffect, useState } from "react";
import LocalStorage from "classes/LocalStorage";
import { useNavigate } from "react-router-dom";
import useMerchant from "hooks/useMerchant";

const StartApplication: React.FC<IStepper> = ({ onNext, onBack }) => {
  const navigate = useNavigate();

  const applicationLocked: boolean = LocalStorage.get<boolean>("application_locked");
  const visitedSteps: string[] = useAppSelector(state => state.app.borrowerVisitedSteps) ?? [];
  const merchant = useMerchant();
  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");
  const locationId: string = LocalStorage.get<any>("selected_location")?.id;

  useEffect(() => {
    if (applicationLocked && visitedSteps.length > 0 && visitedSteps[visitedSteps.length - 1].toLowerCase() === "offers") {
      displayApplicationLockedFeedbackPopup(handleNewApplication, () => { navigate("/Offers") });
    }
    scrollTop();
  }, []);

  useEffect(() => {
    if (applicationLocked) {
      // even though application locked feedback popup already redirects you back to offers, doing it here immediately serves the purpose of changing url back to offers ASAP, so it doesn't look weird while popup is opened
      // if application is locked, you can land on loan information only by typing in url directly or clicking back on offers, so this will not affect other edge cases
      navigate("/Offers", { replace: false });
    } else if (vuid && applicationId) {
      // the application might be from invitation, not landing page
      onNext();
    } else {
      createNewApplication();
    }
  }, []);

  const handleNewApplication = () => {
    prepareNewApplication();
    navigate(`/${merchant?.slug}/location`);
  };

  const createNewApplication = async () => {
    if (!(applicationId && vuid) && locationId) {
      const response = await BorrowerHandler.createLoanApplication(locationId);
      LocalStorage.save("application_id", response.id);
      LocalStorage.save("vuid", response["visitor_token"]);
      // since selected_location is needed only on LoanInformation when creating new application, you can already delete it here - even without deleting, it should work fine
      LocalStorage.remove("selected_location");
      LocalStorage.remove("equal_housing");
    }

    onNext();
  }

  return <>
  </>;
};

export default StartApplication;
